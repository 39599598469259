import Cookies from 'universal-cookie'
import { getJwtCookieName } from './getJwtCookieName'

const cookies = new Cookies()

// For backwards compatibility, we also check for "jwt" for now to allow users with an
// existing JWT (pre-versioning) to stay authenticated. We'll cut over to only the versioned JWTs
// on April 2, 2025.
const getJwtCookie = () => cookies.get(getJwtCookieName()) ?? cookies.get('jwt')

export default getJwtCookie
